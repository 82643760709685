import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

// Components
import MapPanel from '../../../components/organisms/MapPanel'
import PageTemplate from '../../../components/templates/PageTemplate'
import WorldTemplate from '../../../components/templates/WorldTemplate'
import LocalizationContext from '../../../context/Localization/Localization.context'

const MapPage = () => {
  const { language } = useContext(LocalizationContext)
  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Mapa ' : 'Map '}- Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Explora Adina y sus diferentes regiones. Visita los pueblos y lugares importantes del mundo de Dilan.'
              : "Explore Adina and it's differente regions. Visit the towns and important places in the world of Dilan."
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, mundo, mapa'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, world, map'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <WorldTemplate location="map">
        <MapPanel />
      </WorldTemplate>
    </PageTemplate>
  )
}

export default MapPage
