const map = {
  Default: {
    cities: 'cities_label',
    learn_more: 'learn_more'
  },
  ENG: {
    cities: 'Cities',
    learn_more: 'Learn more'
  },
  ESP: {
    cities: 'Ciudades',
    learn_more: 'Ver más'
  },
  ADN: {
    cities: 'Xikarumi',
    learn_more: 'Kirin plor'
  },
}

export { map }
