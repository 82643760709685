import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Localization
import { map } from '../../../config/localization/map'

// Utils
import { createPath, getFont } from '../../../utils/utils'

// Components
import EmblemCard from '../../molecules/EmblemCard'

// Styles
import {
  cityList,
  cityPanel,
  cityTitle,
  cityChevron,
  emblemContainer,
  mobileList,
  mobileImg,
} from './styles.module.scss'

// Models
import { TownModel } from '../../../models'

interface Props {
  towns: TownModel[]
  moveByCoordinates: (x: number, y: number) => void
  setMarker: (townname: string) => void
}

const CityPanel: React.FC<Props> = ({
  towns,
  moveByCoordinates,
  setMarker,
  ...attr
}) => {
  const { language } = useContext(LocalizationContext)

  const [filter, setFilter] = useState(0)
  const [filteredTowns, setFilteredTowns] = useState(
    towns.filter((tw, index) => index >= filter * 4 && index < filter * 4 + 1)
  )

  useEffect(() => {
    setFilteredTowns(
      towns.filter(
        (tw, index) => index >= filter * 4 && index < (filter + 1) * 4
      )
    )
  }, [filter])

  return (
    <div className={cityPanel}>
      <div className={`${cityTitle} ${getFont('font-lato')}`}>
        {map[language].cities}
      </div>
      <div className={`${cityList} only-desktop`}>
        {filteredTowns.map((town) => {
          return (
            <div className={emblemContainer} key={`map-town.${town.name}`}>
              <EmblemCard
                logoPath={`${createPath(town.name)}.png`}
                name={town.name}
                expand
                onClick={() => {
                  setMarker(town.name)
                  moveByCoordinates(
                    town.coordinates[0] / 100,
                    town.coordinates[1] / 100
                  )
                }}
              />
            </div>
          )
        })}
      </div>
      <div className="only-desktop">
        <FontAwesomeIcon
          icon={faChevronLeft}
          className={cityChevron}
          onClick={() => {
            if (filter > 0) {
              setFilter(filter - 1)
            }
          }}
        />
        <FontAwesomeIcon
          icon={faChevronRight}
          className={cityChevron}
          onClick={() => {
            if (filter < 2) {
              setFilter(filter + 1)
            }
          }}
        />
      </div>
      <div className={`${mobileList} only-mobile-tb-flex`}>
        {towns.map((town) => (
          <img
            key={`map-town-d.${town.name}`}
            className={mobileImg}
            src={`https://dilancovak.com/.img/emblems/${createPath(
              town.name
            )}.png`}
            onClick={() => {
              setMarker(town.name)
              moveByCoordinates(
                town.coordinates[0] / 100,
                town.coordinates[1] / 100
              )
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default CityPanel
