import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { navigate } from 'gatsby'

// Contexts
import LocalizationContext from '../../../context/Localization/Localization.context'

// Localization
import { map } from '../../../config/localization/map'

// Models
import { TownModel } from '../../../models'

// Utils
import { createPath, getFont } from '../../../utils/utils'

// Styles
import {
  mapPopUp,
  mapMarker,
  mapEmblem,
  markerCta,
  markerTitle,
  markerChevron,
  markerDescription,
} from './styles.module.scss'

interface MarkerTown extends TownModel {
  marker: boolean
}

interface Props {
  town: MarkerTown
  setMarker: (townname: string) => void
}

const MapMarker: React.FC<Props> = ({ town, setMarker }) => {
  const { language } = useContext(LocalizationContext)
  return (
    <>
      {town.marker ? (
        <div
          style={{
            position: 'absolute',
            left: `calc(${town.coordinates[0]}% - 100px)`,
            top: `calc(${town.coordinates[1]}% - 105px)`,
            zIndex: 3,
          }}
          className={mapPopUp}
        >
          <img
            src={`https://dilancovak.com/.img/emblems/${createPath(
              town.name
            )}.png`}
            className={mapEmblem}
          />
          <div className={`${getFont('font-lato')} ${markerDescription}`}>
            <div className={markerTitle}>{town.name}</div>
            <div>{town.smallDescription[language]}</div>
            <div
              className={markerCta}
              onClick={() => {
                navigate(createPath(town.name))
              }}
            >
              {map[language].learn_more}{' '}
              <FontAwesomeIcon
                icon={faChevronRight}
                className={markerChevron}
              />
              <FontAwesomeIcon
                icon={faChevronRight}
                className={markerChevron}
              />
            </div>
          </div>
        </div>
      ) : null}
      <div
        style={{
          position: 'absolute',
          left: `${town.coordinates[0]}%`,
          top: `${town.coordinates[1]}%`,
          zIndex: 2,
        }}
      >
        <img
          className={mapMarker}
          src="https://dilancovak.com/.img/map/Marker.gif"
          onClick={() => {
            setMarker(town.name)
          }}
        />
      </div>
    </>
  )
}

export default MapMarker
